import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import logo from "../../assets/logo.svg"; 
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Topbar from "../../scenes/global/Topbar";

const Home = () => {
  
  const { t } = useTranslation('translation');
  const { i18n } = useTranslation();
  const [lng, setLng] = useState(i18n.language);
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(5); // Countdown before redirect
  const [stayOnPage, setStayOnPage] = useState(false); // State to track if user wants to stay

  useEffect(() => {
    const handleLanguageChange = (newLng) => {
      setLng(newLng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  // Automatic Redirection Logic
  useEffect(() => {
    if (!stayOnPage) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      const redirectTimeout = setTimeout(() => {
        window.location.href = "https://www.digitaltrainingcompanion.ch"; // Redirect after 5 seconds
      }, 5000);

      return () => {
        clearInterval(timer);
        clearTimeout(redirectTimeout);
      };
    }
  }, [stayOnPage]);

  return (
    <Box display="flex" flexDirection="column" backgroundColor="white" style={{ height: '100vh', overflow: 'auto' }}>
      <Topbar logo={logo} boxShadow={"rgba(0, 0, 0, 0.24) 0px 8px 8px -8px"} />

      {/* Redirection Notification Box */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        width="100vw" // Full width
        minHeight="20vh" // Ensures visibility
        backgroundColor="#FFEBE6"
        padding={4}
      >
        <Typography variant="h5" fontWeight="bold" color="red">
          ⚠️ This version of Digital Training Companion is now obsolete!
        </Typography>
        <Typography variant="h6" mt={1}>
          You will be automatically redirected to the new website in <strong>{countdown} seconds</strong>.
        </Typography>
        <Typography variant="h6" mt={1} mb={2}>
          If you want to continue using this version, press the button below. No data will be deleted.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#D32F2F",
            color: "white",
            "&:hover": { backgroundColor: "#B71C1C" },
          }}
          onClick={() => {
            setStayOnPage(true);
            navigate("/dashboard"); // Redirect to the dashboard if user stays
          }}
        >
          Stay on this version
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
